import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import "../firebaseInit"; // Initialize firebase for the first time

const auth = getAuth();

export async function signIn() {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");

    await signInWithPopup(auth, provider);
  } catch (e) {
    // console.log("signInUser-error", e);
  }
}

export async function getIdToken(forceRefresh = false) {
  const idToken = auth?.currentUser?.getIdToken(forceRefresh);
  if (idToken) {
    return idToken;
  }
  signIn(); // if no token, sign in
  return null;
}

export const signOutUser = signOut;
