import { initializeApp, getApps } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID
};

if (!getApps().length) {
  initializeApp(firebaseConfig);
  const app = initializeApp(firebaseConfig); // https://www.youtube.com/watch?v=Jfkme6WE_Dk
  if (typeof window !== "undefined") { // Check that `window` is in scope for the analytics module!
    if ("measurementId" in firebaseConfig) {
      getAnalytics(app);
      getPerformance();
    }
  }
}
