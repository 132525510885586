/* eslint-disable max-len */
import { openApi } from "./resolver";

const queries = {
  verify_admin_user_login: {
    query: "mutation verify_admin_user_login ($data: VerifyAdminUserLoginInputType!){ verify_admin_user_login(data: $data) { hasError title message userErrors data }}",
    response: (data) => data.data.verify_admin_user_login
  },
  get_delivery_payment_status: {
    query: "query get_delivery_payment_status($data : GetDeliveryPaymentStatusInputType!) { get_delivery_payment_status(data: $data) { hasError title message userErrors data { transactionId paymentStatus transactionDate transactionAmount paymentLink } } }",
    response: (response) => response.data.get_delivery_payment_status
  }
};

export default async function queryOpenApi({ queryTemplate, queryJsonParams }) {
  if (!queryTemplate || !queries[queryTemplate]) {
    throw new Error("ERROR: Invalid parameter \"queryTemplate\" provided");
  }

  const payload = {};

  payload.query = queries[queryTemplate].query;
  payload.variables = { data: queryJsonParams };
  const response = await openApi({
    extraHeader: {},
    data: payload
  });

  if (response && response.data) {
    return queries[queryTemplate].response(response.data);
  }

  return response;
}
